import React from "react";
import Layout from "../components/Layout";
import ExternalLink from "../components/ExternalLink";

const LINKED_IN = "https://www.linkedin.com/in/fred-c/";
const GUN_IO =
  "https://app.gun.io/app/freelancer/ee077e0a-26b3-4c8f-94cb-0d7afc45211b/";
const GITHUB = "https://github.com/fredcollman";

const ContactPage = () => {
  return (
    <Layout>
      <h1>{"Don't be a stranger"}</h1>
      <h2>By email</h2>
      {"If you'd like to say hi, drop me a message at "}
      <span className="italic">fred collman at gmail dot com</span>
      <h2>Professional bits</h2>
      <dl>
        <dt>LinkedIn</dt>
        <dd>
          <ExternalLink href={LINKED_IN}>{LINKED_IN}</ExternalLink>
        </dd>
        <dt>Gun.io</dt>
        <dd>
          <ExternalLink href={GUN_IO}>{GUN_IO}</ExternalLink>
        </dd>
        <dt>GitHub</dt>
        <dd>
          <ExternalLink href={GITHUB}>{GITHUB}</ExternalLink>
        </dd>
      </dl>
    </Layout>
  );
};

export default ContactPage;
